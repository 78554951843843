.lg-react-element {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
    column-gap: 20px;
    row-gap: 30px;

   
}

.gallery-item {
    margin: 5px;
  }


  @media only screen and (max-width: 960px) {
    .lg-react-element{
        grid-template-columns: auto;
    }
   
    
}