.rotate {
    width: 100px;
    animation: rotation 2s infinite linear;
  }


  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }